<template>
  <div class="body">
    <div style="padding-top: 35px">
      <p class="title">下载聚瑞云控APP</p>
      <p class="subtitle">实时查询定位信息</p>
    </div>
    <div
      class="downloadBtn"
      v-if="getMobileOperatingSystem() !== 'IOS'"
      @click="downloadApp('android')"
    >
      <img src="./images/android.png" />
      <p class="subtitle">安卓版下载</p>
    </div>
    <div class="downloadBtn" v-else @click="downloadApp('ios')">
      <img src="./images/ios.png" />
      <p class="subtitle">IOS下载</p>
    </div>

    <img src="./images/logo.png" class="logo" />
    <div class="tip" v-if="!is_weixn()">
      <img src="./images/zhiyin.png" />
    </div>
  </div>
</template>

<script>
import { getDownloadUrl } from "@/api/juruiApp.js";
export default {
  data() {
    return {
      downloadData: {},
    };
  },
  mounted() {},
  methods: {
    downloadApp(type) {
      const url = this.downloadData[type].downloadUrl;
      window.location.href = url;
    },
    is_weixn() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return false;
      } else {
        return true;
      }
    },
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (
        userAgent.match(/iPhone/i) ||
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPod/i) ||
        userAgent.match(/Macintosh/i) ||
        userAgent.match(/iOS/i)
      ) {
        return "IOS";
      } else if (userAgent.match(/Android/i)) {
        return "Android";
      } else {
        return "unknown";
      }
    },
  },
  async created() {
    const res = await getDownloadUrl();
    if (!res.success) return;
    this.downloadData = res.content;
  },
};
</script>

<style scpoed>
.body {
  width: 100vw;
  height: 100vh;
  background: url("./images/bg.png") no-repeat;
  background-size: cover;
  background-position: top;
}
.title {
  font-size: 28px;
  color: #fff;
  text-align: center;
  /* margin-top: 40%; */
}
.subtitle {
  font-size: 22px;
  color: #fff;
  text-align: center;
  /* margin-top: 20px; */
}
.downloadBtn {
  width: 5rem;
  height: 1.2rem;
  border-radius: 1rem;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3a7df6;
  /* width: 300px;
  height: 50px;
  border-radius: 25px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 150px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3a7df6; */
}
.downloadBtn img {
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 10px;
}
.logo {
  width: 4rem;
  position: fixed;
  bottom: 0.7rem;
  left: 50%;
  transform: translateX(-50%);
}
.tip {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  /* align-items: center; */
  justify-content: center;
  padding-top: 80px;
}
.tip img {
  width: 100%;
  height: 240px;
}
</style>
